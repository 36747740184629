import axios from "axios"
import { saveToken, saveUser } from "../auth"
import { updateAbilities } from "../configs/acl/utils"
import { BACK_END_URL } from "./config"

const register = async (user) => {
    try {
        const response = await axios.post(
            `${BACK_END_URL}/public/register`,
            user
        )
        console.log("[INFO] Register Success", response)
        return [true, response]
    } catch (error) {
        console.log("[ERROR] Register Failed", error)
        return [false, error?.response?.data]
    }
}

const login = async (user) => {
    try {
        const response = await axios.post(
            `${BACK_END_URL}/public/login`,
            user
        )
        console.log("[INFO] Login Success", response)
        saveToken(response.data.data.accessToken)
        saveUser(response.data.data.user)
        updateAbilities()
        return [true, response]
    } catch (error) {
        console.log("[ERROR] Login Failed", error)
        if (error && error.response && error.response.data) return [false, error.response.data]
        else return [false, { msg: "unexpected error" }]
    }
}

const logout = () => localStorage.clear()

export {
    login,
    register,
    logout
}

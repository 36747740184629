import {
  Calendar,
  CheckSquare,
  Circle,
  Home,
  MessageSquare
} from "react-feather"
import { RESOURCES, ROLE_ACTIONS } from "../../configs/acl/roleAbilities"

export default [
  {
    id: "dashboards",
    title: "Dashboards",
    icon: <Home size={20} />,
    badge: 'danger',
    badgeText: '2',
    children: [
      {
        id: "students",
        title: "Students",
        icon: <Circle size={20} />,
        navLink: "/dashboards/students",
        action: ROLE_ACTIONS.read,
        resource: RESOURCES.students
      },
      {
        id: "curriculum",
        title: "Curriculum",
        icon: <Circle size={20} />,
        navLink: "/dashboards/curriculum",
        action: ROLE_ACTIONS.read,
        resource: RESOURCES.curriculum
      },
      {
        id: "courses",
        title: "Courses",
        icon: <Circle size={20} />,
        navLink: "/dashboards/courses",
        action: ROLE_ACTIONS.read,
        resource: RESOURCES.courses
      }
    ]
  },
  {
    header: "Apps",
    action: ROLE_ACTIONS.read,
    resource: RESOURCES.apps
  },
  {
    id: "events",
    title: "Events",
    icon: <Calendar size={20} />,
    navLink: "/events",
    action: ROLE_ACTIONS.read,
    resource: RESOURCES.events
  },
  {
    id: "rpm",
    title: "RPM",
    icon: <MessageSquare size={20} />,
    navLink: "/rpm",
    action: ROLE_ACTIONS.read,
    resource: RESOURCES.rpm
  },
  {
    id: "support",
    title: "Support",
    icon: <CheckSquare size={20} />,
    navLink: "/support",
    action: ROLE_ACTIONS.read,
    resource: RESOURCES.support
  },
  {
    id: "leadership",
    title: "Leadership",
    icon: <MessageSquare size={20} />,
    navLink: "/leadership",
    action: ROLE_ACTIONS.read,
    resource: RESOURCES.leadership
  },
  {
    id: "microcredentials",
    title: "Microcredentials",
    icon: <CheckSquare size={20} />,
    navLink: "/microcredentials",
    action: ROLE_ACTIONS.read,
    resource: RESOURCES.microcredentials
  }
]

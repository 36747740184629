// * Reactstrap Imports
import { Container, Spinner } from "reactstrap"

// ** Logo
import logo from "@src/assets/images/logo/logo.svg"

const FallbackLoader = () => {
    return (
        <Container className="d-flex flex-column align-items-center justify-content-center vh-100">
            <img className="fallback-logo" src={logo} alt="logo" width={350} height={350} />
            <Spinner color="primary" className="mt-2" />
        </Container>
    )
}

export default FallbackLoader

export const USER_ROLES = {
    teacher: "teacher",
    student: "student",
    admin: "admin"
}

export const ROLE_ACTIONS = {
    read: "read"
}

export const RESOURCES = {
    students: "students",
    curriculum: "curriculum",
    events: "events",
    rpm: "rpm",
    support: "support",
    apps: "apps",
    leadership: "leadership",
    microcredentials: 'microcredentials',
    courses: "courses"
}

export default {
    [USER_ROLES.teacher]: [
        {
            action: ROLE_ACTIONS.read,
            subject: RESOURCES.students
        },
        {
            action: ROLE_ACTIONS.read,
            subject: RESOURCES.curriculum
        },
        {
            action: ROLE_ACTIONS.read,
            subject: RESOURCES.events
        },
        {
            action: ROLE_ACTIONS.read,
            subject: RESOURCES.rpm
        },
        {
            action: ROLE_ACTIONS.read,
            subject: RESOURCES.support
        },
        {
            action: ROLE_ACTIONS.read,
            subject: RESOURCES.apps
        }
    ],
    [USER_ROLES.student]: [
        {
            action: ROLE_ACTIONS.read,
            subject: RESOURCES.students
        },
        {
            action: ROLE_ACTIONS.read,
            subject: RESOURCES.courses
        },
        {
            action: ROLE_ACTIONS.read,
            subject: RESOURCES.events
        },
        {
            action: ROLE_ACTIONS.read,
            subject: RESOURCES.leadership
        },
        {
            action: ROLE_ACTIONS.read,
            subject: RESOURCES.microcredentials
        },
        {
            action: ROLE_ACTIONS.read,
            subject: RESOURCES.apps
        }
    ]
}
import jwtDecode from "jwt-decode"
import { USER_ROLES } from "./../configs/acl/roleAbilities"
import { getUserData } from "./../utility/Utils"

export const ACCESS_TOKEN_NAME = "accessToken"

/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = () => localStorage.getItem(ACCESS_TOKEN_NAME)

export const getTokenData = () => {
    const accessToken = localStorage.getItem(ACCESS_TOKEN_NAME)
    if (!accessToken) return null
    return jwtDecode(accessToken)
}

export const saveToken = (token) => localStorage.setItem(ACCESS_TOKEN_NAME, JSON.stringify(token))
export const saveUser = (user) => localStorage.setItem("userData", JSON.stringify(user))

/**
 * Return object with isTeacher, isStudent, isAdmin boolean attributes
 */
export const getUserType = () => {
    const user = getTokenData()
    if (!user || !user.type) return {
        isTeacher: false,
        isStudent: false,
        isAdmin: false
    }
    const userType = user.type

    let isTeacher = false
    let isStudent = false
    let isAdmin = false

    switch (userType) {
        case USER_ROLES.admin:
            isAdmin = true
            break

        case USER_ROLES.student:
            isStudent = true
            break

        case USER_ROLES.teacher:
            isTeacher = true
            break

        default:
            break
    }

    return { isAdmin, isStudent, isTeacher, userType }
}

export const useAuth = () => getUserData()


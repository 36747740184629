// ** React Imports
import { Fragment, lazy } from "react"
import { Navigate } from "react-router-dom"
// ** Layouts
import BlankLayout from "@layouts/BlankLayout"
import VerticalLayout from "@src/layouts/VerticalLayout"
import HorizontalLayout from "@src/layouts/HorizontalLayout"
import LayoutWrapper from "@src/@core/layouts/components/layout-wrapper"

// ** Route Components
import PublicRoute from '@components/routes/PublicRoute'
import PrivateRoute from '@components/routes/PrivateRoute'

const DefaultRoute = "/login"

// ** Utils
import { isObjEmpty } from "../../utility/Utils"

import { isUserLoggedIn } from "../../auth"

// * Abilities
import { ROLE_ACTIONS, RESOURCES } from "../../configs/acl/roleAbilities"

const getLayout = {
  blank: <BlankLayout />,
  vertical: <VerticalLayout />,
  horizontal: <HorizontalLayout />
}

// ** Document title
const TemplateTitle = "Lia"

// ** Default Route
const getHomeRoute = () => {
  if (localStorage.getItem("accessToken")) return "/dashboards/students"
  else return '/login'
}

// * Dashboards
const Students = lazy(() => import("../../views/Dashboards/Students"))
const Curriculum = lazy(() => import("../../views/Dashboards/Curriculum"))
const Courses = lazy(() => import("../../views/Dashboards/Courses"))

// * Teacher Specific Route Views
const Events = lazy(() => import("../../views/Teacher/Events"))
const RPM = lazy(() => import("../../views/Teacher/RPM"))
const Support = lazy(() => import("../../views/Teacher/Support"))

// * Student Specific Route Views
const Leadership = lazy(() => import("../../views/Student/Leadership"))
const Microcredentials = lazy(() => import("../../views/Student/Microcredentials"))

// * Common Views
const Login = lazy(() => import("../../views/Login"))
const Register = lazy(() => import("../../views/Register"))
const ForgotPassword = lazy(() => import("../../views/ForgotPassword"))
const Error = lazy(() => import("../../views/Error"))
const NotAuthorized = lazy(() => import("../../views/NotAuthorized"))

// ** Merge Routes
const Routes = [
  {
    path: "/",
    index: true,
    element: <Navigate replace to={getHomeRoute()} />,
    meta: {
      publicRoute: true
    }
  },
  {
    path: "/dashboards/students",
    element: <Students />,
    meta: {
      action: ROLE_ACTIONS.read,
      resource: RESOURCES.students,
      privateRoute: true
    }
  },
  {
    path: "/dashboards/courses",
    element: <Courses />,
    meta: {
      action: ROLE_ACTIONS.read,
      resource: RESOURCES.courses,
      privateRoute: true
    }
  },
  {
    path: "/dashboards/curriculum",
    element: <Curriculum />,
    meta: {
      action: ROLE_ACTIONS.read,
      resource: RESOURCES.curriculum,
      privateRoute: true
    }
  },
  {
    path: "/events",
    element: <Events />,
    meta: {
      action: ROLE_ACTIONS.read,
      resource: RESOURCES.events,
      privateRoute: true
    }
  },
  {
    path: "/leadership",
    element: <Leadership />,
    meta: {
      action: ROLE_ACTIONS.read,
      resource: RESOURCES.leadership,
      privateRoute: true
    }
  },
  {
    path: "/microcredentials",
    element: <Microcredentials />,
    meta: {
      action: ROLE_ACTIONS.read,
      resource: RESOURCES.microcredentials,
      privateRoute: true
    }
  },
  {
    path: "/rpm",
    element: <RPM />,
    meta: {
      action: ROLE_ACTIONS.read,
      resource: RESOURCES.rpm,
      privateRoute: true
    }
  },
  {
    path: "/support",
    element: <Support />,
    meta: {
      publicRoute: true
    }
  },
  {
    path: "/login",
    element: <Login />,
    meta: {
      layout: "blank",
      publicRoute: true
    }
  },
  {
    path: "/register",
    element: <Register />,
    meta: {
      layout: "blank",
      publicRoute: true
    }
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
    meta: {
      layout: "blank",
      publicRoute: true
    }
  },
  {
    path: "/error",
    element: <Error />,
    meta: {
      layout: "blank",
      publicRoute: true
    }
  },
  {
    path: "/misc/not-authorized",
    element: <NotAuthorized />,
    meta: {
      layout: "blank",
      publicRoute: true
    }
  }
]

const getRouteMeta = (route) => {
  if (isObjEmpty(route.element.props)) {
    if (route.meta) {
      return { routeMeta: route.meta }
    } else {
      return {}
    }
  }
}

// ** Return Filtered Array of Routes & Paths
const MergeLayoutRoutes = (layout, defaultLayout) => {
  const LayoutRoutes = []

  if (Routes) {
    Routes.filter(route => {
      let isBlank = false
      // ** Checks if Route layout or Default layout matches current layout
      if (
        (route.meta && route.meta.layout && route.meta.layout === layout) ||
        ((route.meta === undefined || route.meta.layout === undefined) && defaultLayout === layout)
      ) {
        let RouteTag = PrivateRoute

        // ** Check for public or private route
        if (route.meta) {
          route.meta.layout === 'blank' ? (isBlank = true) : (isBlank = false)
          RouteTag = route.meta.publicRoute ? PublicRoute : PrivateRoute
        }
        if (route.element) {
          const Wrapper =
            // eslint-disable-next-line multiline-ternary
            isObjEmpty(route.element.props) && isBlank === false
              ? // eslint-disable-next-line multiline-ternary
              LayoutWrapper
              : Fragment

          route.element = (
            <Wrapper {...(isBlank === false ? getRouteMeta(route) : {})}>
              <RouteTag route={route}>{route.element}</RouteTag>
            </Wrapper>
          )
        }

        // Push route to LayoutRoutes
        LayoutRoutes.push(route)
      }
      return LayoutRoutes
    })
  }
  return LayoutRoutes
}

const getRoutes = (layout) => {
  const defaultLayout = layout || "vertical"
  const layouts = ["vertical", "horizontal", "blank"]

  const AllRoutes = []

  layouts.forEach((layoutItem) => {
    const LayoutRoutes = MergeLayoutRoutes(layoutItem, defaultLayout)

    AllRoutes.push({
      path: "/",
      element: getLayout[layoutItem] || getLayout[defaultLayout],
      children: LayoutRoutes
    })
  })
  return AllRoutes
}

export { DefaultRoute, TemplateTitle, Routes, getRoutes }
